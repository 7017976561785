.App {
  background-color: #1a1d1e;
  color: #e0e0e0;
  min-height: 100vh;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: calc(100vh - 5vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #bb86fc;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #131314;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #e3e3e3;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #13131461;
  border-color: #8e918f1f;
}

.gsi-material-button:disabled .gsi-material-button-state {
  background-color: #e3e3e31f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: white;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: white;
  opacity: 8%;
}

.auth-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  height: 40px;
  margin: 2px 0 2px 0;
  background-color: #131314;
  border: 1px solid #747775;
  border-radius: 4px;
  color: #e3e3e3;
  font-family: 'Roboto', arialm sans-serif;
  font-size: 14px;
  cursor: pointer;
  padding: 0 12px;
  box-sizing: border-box;
}

.auth-button:hover {
  background-color: #333;
}

.auth-button img {
  left: 12px;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
}

.auth-button-text {
  display: block;
  text-align: center;
  width: calc(100% - 32px);
  font-weight: 500;
}

.card {
  background-color: #202425;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.7);
  border: 1px solid #e3e3e31f;
}

.login-card {
  background-color: #121212;
}

.login-header {
  background-color: #1c1c1c;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.login-body {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.rave-logo {
  width: 80%;
}

.login-unavailable {
  color: #888;
  font-size: 0.8rem;
  text-align: center;
}

.footer-copyright {
  background-color: #1c1c1c;
  font-size: 0.75rem;
  text-align: center;
  width: 100%;
}

a {
  color: #bb86fc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.error-message {
  color: #ff6b6b;
  text-align: center;
  margin-top: 20px;
}

.profile-button {
  height: 32px;
  margin: 10px 0;
  background-color: transparent;
  border: 1px solid #3a3f42;
  font-weight: 500;
  border-radius: 4px;
  color: #e3e3e3;
  font-family: 'Roboto', arialm sans-serif;
  font-size: 14px;
  cursor: pointer;
  padding: 0 16px;
  box-sizing: border-box;
}

.logout-button {
  color: #d30000;
}

.profile-button:hover {
  background-color: #333;
}

.logout-button:hover {
  background-color: rgba(134, 0, 0, 0.20);
}
